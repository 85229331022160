import { saveSession } from '@/common/AuthApi';
import { SessionDocument, useLoginMutation } from '@/common/graphql';
import { useApollo } from '@/common/lib/ApolloClient';
import { globalJwtManager } from '@/common/lib/JwtManager';
import { useState } from 'react';

export const useLogin = () => {
  const apolloClient = useApollo();
  const [login] = useLoginMutation();
  const [isLoading, setIsLoading] = useState(false);

  return {
    login: async ({ email, password }: { email: string; password: string }) => {
      setIsLoading(true);
      try {
        const res = await login({ variables: { email, password } });
        if (
          res.data?.login?.session?.user &&
          res.data?.login?.session.refreshToken
        ) {
          globalJwtManager.setToken(res.data.login.token);
          globalJwtManager.setRefreshToken(res.data.login.session.refreshToken);

          // update the session in the cache
          apolloClient.writeQuery({
            query: SessionDocument,
            data: {
              session: res.data.login.session,
            },
          });

          await saveSession(res.data.login.session.refreshToken);
        }
        return res;
      } finally {
        setIsLoading(false);
      }
    },
    isLoading,
  };
};
