import { NEXT_PATH_QUERY_PARAM } from '@/common/constants/AppConstants';
import { UserSessionType } from '@/common/graphql';
import { useCurrentSession } from '@/features/auth/hooks/useCurrentSession';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

interface UnauthenticatedProps {
  authRedirectUrl?: string;
  getAuthRedirectUrl?: (user: UserSessionType) => string;
}

const DEFAULT_AUTH_REDIRECT_URL = '/';

export const Unauthenticated = ({
  children,
  getAuthRedirectUrl,
  authRedirectUrl = DEFAULT_AUTH_REDIRECT_URL,
}: PropsWithChildren<UnauthenticatedProps>) => {
  const currentSession = useCurrentSession();
  const { isAuthenticated } = currentSession;
  const router = useRouter();
  const { query } = router;
  const { [NEXT_PATH_QUERY_PARAM]: nextPath, ...otherQueryParams } = query;

  useEffect(() => {
    if (isAuthenticated) {
      const pathname =
        (nextPath as string) ||
        getAuthRedirectUrl?.(currentSession as any) ||
        authRedirectUrl;
      router.push({ pathname, query: otherQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return !isAuthenticated ? <>{children}</> : null;
};
