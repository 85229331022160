import { useRouter } from 'next/router';

export const useLinkWithCurrentQuery = () => {
  const router = useRouter();

  return {
    getLink: (pathname: string) => {
      const search = router.asPath.split('?')[1];
      return pathname + (search ? '?' + search : '');
    },
  };
};
