import { useSessionQuery } from '@/common/graphql';

/**
 * Returns the current user session for the logged in user
 */
export const useCurrentSession = () => {
  const { data, loading: sessionLoading } = useSessionQuery({
    fetchPolicy: 'cache-only',
  });
  const user = data?.session?.user;
  const merchantMembers = data?.session?.merchantMembers;
  const salesChannels = data?.session?.salesChannels;
  const affiliateOrganizationMembers =
    data?.session?.affiliateOrganizationMembers;
  return {
    user,
    isAuthenticated: !!user?.email,
    salesChannels: salesChannels ?? [],
    merchantMembers,
    sessionLoading,
    affiliateOrganizationMembers,
  };
};
