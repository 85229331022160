import { useLinkWithCurrentQuery } from '@/common/hooks/useLinkWithCurrentQuery';
import { getUserRedirectURL } from '@/common/utils/Utils';
import { Unauthenticated } from '@/components/Unauthenticated';
import { LoginForm } from '@/features/auth/components/login/LoginForm';
import {
  Box,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { ReactElement } from 'react';
import PublicLayout from './layouts/PublicLayout';

export default function Login() {
  const { getLink: getLinkWithCurrentQuery } = useLinkWithCurrentQuery();

  return (
    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={16} px={6}>
      <Box
        rounded={'lg'}
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow={'lg'}
        p={8}
      >
        <Stack align={'center'}>
          <Heading fontWeight={500} fontSize={24}>
            Sign in to Truemed
          </Heading>
        </Stack>
        <Divider my={4} />
        <LoginForm />
      </Box>
      <Stack align={'center'}>
        <Text>
          New to Truemed?{' '}
          <Link
            color={'blue.400'}
            href={getLinkWithCurrentQuery('/sign-up')}
            as={NextLink}
          >
            Create an account
          </Link>
        </Text>
      </Stack>
    </Stack>
  );
}

Login.getLayout = function (page: ReactElement | ReactElement[]) {
  return (
    <Unauthenticated getAuthRedirectUrl={getUserRedirectURL}>
      <PublicLayout>{page}</PublicLayout>
    </Unauthenticated>
  );
};
