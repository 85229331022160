import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useForm } from 'react-hook-form';
import { useLogin } from '../../hooks/useLogin';

interface LoginFormFields {
  email: string;
  password: string;
}

export function LoginForm({}): React.ReactElement {
  const { login, isLoading: isLoggingIn } = useLogin();

  const { formState, handleSubmit, register, setError } =
    useForm<LoginFormFields>();

  const submit = ({ email, password }: LoginFormFields) => {
    login({ email, password })
      .then((res) => {
        const user = res.data?.login?.session?.user;
        if (!user) {
          throw new Error('Invalid login credentials');
        }
      })
      .catch(() => {
        setError('root.loginError', { message: 'Invalid login credentials' });
      });
  };

  const errorMessage =
    formState.errors?.email?.message ||
    formState.errors?.password?.message ||
    formState.errors?.root?.loginError?.message;

  return (
    <Stack spacing={4} as="form" onSubmit={handleSubmit(submit)}>
      <FormControl id="email">
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeholder="Email Address"
          {...register('email', {
            required: 'Please input your email',
          })}
        />
      </FormControl>
      <FormControl id="password">
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          placeholder="Password"
          {...register('password', {
            required: 'Please input your password',
          })}
        />
      </FormControl>
      {errorMessage && (
        <Alert status="warning">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
      <Button
        my={4}
        size={'lg'}
        w={'100%'}
        bg={'blue.400'}
        color={'white'}
        _hover={{
          bg: 'blue.500',
        }}
        type="submit"
        isLoading={isLoggingIn}
      >
        Login
      </Button>
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        align={'center'}
        justify={'center'}
      >
        <Link color={'blue.400'} as={NextLink} href="/reset-password">
          Need to set or reset a password?
        </Link>
      </Stack>
    </Stack>
  );
}
