import { MainLogo } from '@/components/MainLogo';
import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';
import FooterLayout from './FooterLayout';

interface PublicLayoutProps {
  children: ReactElement | ReactElement[];
}
const PublicLayout = ({ children }: PublicLayoutProps) => {
  return (
    <>
      <Box as="header" w="full" top="0" left="0" right="0" bg="white">
        <MainLogo />
      </Box>
      <Box w={'full'} padding="16px" bg="transparent">
        {children}
      </Box>
      <FooterLayout />
    </>
  );
};

export default PublicLayout;
