import { getUserRedirectURL } from '@/common/utils/Utils';
import { Unauthenticated } from '@/components/Unauthenticated';
import { ReactElement } from 'react';
import PublicLayout from './layouts/PublicLayout';
import Login from './login';

export default function Home() {
  return <Login />;
}

Home.getLayout = function (page: ReactElement | ReactElement[]) {
  return (
    <Unauthenticated getAuthRedirectUrl={getUserRedirectURL}>
      <PublicLayout>{page}</PublicLayout>
    </Unauthenticated>
  );
};
